import React, { useEffect } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'
import Button from './atoms/Button'
import Usa from '../assets/icons/usa'
import Hardhat from '../assets/icons/hard-hat'
import Boxusd from '../assets/icons/boxusd'
import HeroRatingStars from './hero-rating-stars'
import Slider from 'react-slick'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { motion } from "framer-motion";

const SliderContainer = styled.div`
  &:hover {
    .PrevNextArrowsContainer {
      opacity: 1 !important;
    }
  }
  .PrevNextArrowsContainer {
    opacity: 0.1;
    background-color: rgba(204, 204, 204, 0.4);
    &:hover {
      background-color: rgba(204, 204, 204, 0.9);
    }
  }
  &.server-rendered {
    .slick-track {
      left: 0% !important;
    }
  }
`
const PrevNextArrowsContainer = styled.div`
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    padding: 5px;
  }
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;

  transition: 0.2s ease all;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  &.carousel-icon-right {
    right: 18px;
  }
  &.carousel-icon-left {
    left: 18px;
  }
`


const NextArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-left PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronLeft size={26} />
    </PrevNextArrowsContainer>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-right PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronRight size={26} />
    </PrevNextArrowsContainer>
  )
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const HeroContent = ({
  heroTitle,
  heroImage,
  description,
  primaryActionBtnLabel,
  primaryActionBtnLink,
  style,
  alt,
  preloadImage,
  hideImage,
}) => (
  <>

    <section className="bg-white overflow-hidden relative">
      <div className='hidden md:block Block-background HeroImageBlock-background u-bgColor--transparentOrange absolute right-0 top-0 bottom-20 bg-brand-50' style={{ zIndex: 0, width: '60%' }} />
      <div className=' max-w-7xl flex flex-wrap md:flex-nowrap items-center 	mx-auto heroImageBlock-home-container z-1' >

        <div className='w-full md:w-1/2 md:pr-12 px-6 md:px-8 mb-12 md:mb-0 py-8 md:py-20 shrink-0 z-1 relative	'>

          <span className='text-accent-500 mb-2 block font-semibold md:text-lg'>LV's Iron Works</span>
          <h1 className='font-display font-extrabold text-3xl sm:text-4xl md:text-5xl text-brand-600'>{heroTitle}</h1>
          <p className=' mb-4 pt-4 text-base md:text-lg text-gray-500 md:leading-7'>

            {description && description.description && (
              <Markdown
                className="feature-extended-image has-animation"
                children={description.description}
              />
            )}

          </p>


          {primaryActionBtnLabel && <div className='mt-4 '><Button color='accent' to={primaryActionBtnLink}>{primaryActionBtnLabel}</Button></div>}
          <HeroRatingStars alignLeftAlways={true} classes="text-left md:text-left" />
        </div>

        <div className='heroImageBlock-home-img  w-full  h-96 md:h-full relative group  md:pb-0   border-b-8 border-accent-500 md:border-none' >


          <GatsbyImage
            image={getImage(heroImage)}
            alt={alt}

            loading={preloadImage}
            className="h-full z-10"
          />


        </div>
      </div>
    </section>
  </>

)

const HeroArea = ({ heroContent }) => {

  let isMultiSlide = false

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true
  }

  const isBuildingHTML = typeof window === 'undefined'


  if (Array.isArray(heroContent)) {
    heroContent = heroContent[0]
  }

  // return <HeroContent {...heroContent} preloadImage />

  if (Array.isArray(heroContent)) {
    return (
      <SliderContainer className={isBuildingHTML ? 'server-rendered' : null}>
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroContent
              {...item}
              alt={item.heroTitle}
              key={index}
              preloadImage={index == 0}
            />
          ))}
        </Slider>
      </SliderContainer>
    )
  } else {
    return <HeroContent {...heroContent} preloadImage />
  }
}

export default HeroArea
